<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <v-skeleton-loader
        type="chip"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <v-toolbar-title v-else class="headline font-weight-bold"
        >Quests</v-toolbar-title
      >

      <v-spacer></v-spacer>

      <v-row v-show="true" align="center" style="max-width: 300px;">
        <v-text-field
          class="search"
          ref="searchField"
          flat
          hide-details
          solo
          rounded
          clearable
          background-color="grey lighten-3"
          label=""
          v-model="filter.q"
        ></v-text-field>
      </v-row>
      <v-select
        class="ml-10"
        hide-details
        dense
        item-text="label"
        item-value="value"
        :items="questStatusList"
        style="max-width: 200px;"
        @change="filterQuest"
        v-model="filter.status"
      />

      <v-btn class="ml-5" icon @click="getQuests()">
        <img src="@/assets/icons/search.svg" alt height="17.7px" />
      </v-btn>
    </v-app-bar>

    <div
      v-show="currentView === 'list'"
      class="mt-12 mx-4"
      id="user-list"
      v-infinite-scroll="loadMore"
      :infinite-scroll-disabled="loading"
      infinite-scroll-distance="100"
    >
      <v-simple-table>
        <thead>
          <tr>
            <th width="6%" class="text-left pl-0">ID</th>
            <th width="18%" class="text-left pl-0">Title</th>
            <th width="30%" class="text-left">Description</th>
            <th width="18%" class="text-left">Status</th>
            <th width="11%" class="text-left">Created By</th>
            <th width="15%" class="text-left">Created At</th>
            <th></th>
          </tr>
        </thead>
        <tbody v-show="!loading">
          <tr
            v-for="quest in quests"
            v-bind:key="quest.quest_id"
            class="font-weight-8"
          >
            <td class="pl-0">
              {{ quest.quest_id }}
            </td>
            <td class="pl-0">
              {{ quest.title }}
            </td>
            <td>{{ limitDescription(quest.description) }} ...</td>
            <td style="text-transform: capitalize">{{ quest.status }}</td>
            <td>
              {{ quest.posted_by ? quest.posted_by.full_name : ' ' }}
            </td>
            <td>
              {{ quest.posted_at }}
            </td>
            <td>
              <v-menu>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    rounded
                    fab
                    x-small
                    elevation="0"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>
                      {{ icons.menu }}
                    </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item v-if="canHide(quest)" @click="hideQuest(quest)">
                    Hide
                  </v-list-item>
                  <v-list-item
                    v-if="canApprove(quest)"
                    @click="approveQuest(quest)"
                  >
                    Approve
                  </v-list-item>
                  <v-list-item @click="confirmDeleteQuest(quest)">
                    Delete
                  </v-list-item>
                </v-list>
              </v-menu>
              <!-- <a href="javascript:" @click="editQuest(quest.quest_id)">Edit</a> -->
            </td>
          </tr>
        </tbody>
        <tbody>
          <template v-if="loading">
            <tr v-for="index in 10" :key="index + '-skeleton'">
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
              <td class="pl-0">
                <v-skeleton-loader
                  type="list-item"
                  transition="fade-transition"
                />
              </td>
            </tr>
          </template>
        </tbody>
      </v-simple-table>
      <v-pagination
        :length="meta.last_page"
        class="my-6"
        @input="updatePage"
        v-show="meta.last_page > 1"
        v-model="meta.current_page"
      ></v-pagination>
      <div
        class="d-flex justify-center mt-3"
        v-if="!(quests.length > 0) && !loading"
      >
        <p>No match found.</p>
      </div>
    </div>

    <v-row>
      <v-col>
        <div v-show="currentView === 'edit'">
          <div style="">
            <label class="text-field-label ml-1">Quest Title</label>
            <v-text-field
              flat
              solo
              required
              class="mt-2 pb-0"
              v-model="quest.title"
            >
            </v-text-field>
          </div>
          <div>
            <label class="text-field-label ml-1">Description</label>
            <v-textarea
              flat
              solo
              required
              class="mt-2"
              v-model="quest.description"
            ></v-textarea>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-snackbar v-model="snackbar.show" :color="snackbar.color" right>
      {{ snackbar.message }}
      <v-btn color="white" text @click="snackbar.show = false">Ok</v-btn>
    </v-snackbar>

    <ConfirmModal
      v-model="deleteQuestDialog.model"
      title="Delete Quest"
      message="Are you sure you want to delete this Quest?"
      @cancel="closeDeleteQuestDialog()"
      @confirm="deleteQuest(deleteQuestDialog.quest)"
    ></ConfirmModal>
  </div>
</template>

<script>
import Api from '@/services/api'
import ConfirmModal from '@/components/modals/ConfirmModal'
import infiniteScroll from 'vue-infinite-scroll'
import { mdiDotsVertical } from '@mdi/js'

import { QUEST_STATUS } from '@/store/modules/quests'

export default {
  name: 'QuestsPage',

  components: {
    ConfirmModal
  },

  directives: {
    infiniteScroll
  },

  data() {
    return {
      icons: {
        menu: mdiDotsVertical
      },
      quests: [],
      quest: {},
      deleteQuestDialog: {
        model: false,
        quest: null
      },
      snackbar: {},
      currentView: 'list',
      loading: false,
      showSearch: false,
      showProfile: false,
      selectedProfile: null,
      filter: {
        q: '',
        status: ''
      },
      searchableStatus: [
        'completed',
        'in progress',
        'posted',
        'completed by helper',
        'completed by family'
      ],
      defaultFilter: '',
      sortOrder: true,
      meta: {
        current_page: 1,
        from: 1,
        last_page: 1,
        path: '',
        per_page: 15,
        to: 6,
        total: 6
      },
      questStatusList: []
    }
  },

  created() {},

  computed: {},
  mounted() {
    this.getQuests()
    this.getQuestStatus()
  },
  methods: {
    limitDescription(description) {
      if (!description) {
        return ''
      }
      let str = String(description)
      return str.substring(0, 120)
    },
    editQuest(questId) {
      this.currentView = 'edit'
    },
    loadMore: function() {},
    async getQuests(reset = 0) {
      this.loading = true
      let vm = this
      if (reset) {
        this.meta.current_page = 1
      }

      let response = await Api.get(
        '/quests?all=1&without_draft=1&page=' + this.meta.current_page,
        {
          responseType: 'json',
          params: vm.filter
        }
      )
      this.loading = false
      this.quests = response.data.data
      this.meta = response.data.meta
    },

    async getQuestStatus() {
      this.loading = true
      let response = await Api.get('/quests/status', {
        responseType: 'json'
      })
      this.loading = false
      this.questStatusList = response.data.data
      this.questStatusList.unshift(' ')
    },

    canHide(quest) {
      return (
        quest.status === QUEST_STATUS.PENDING ||
        quest.status === QUEST_STATUS.POSTED
      )
    },
    canApprove(quest) {
      return quest.status === QUEST_STATUS.IN_REVIEW
    },

    async hideQuest(quest) {
      const currentStatus = quest.status

      try {
        quest.status = QUEST_STATUS.IN_REVIEW

        const { data: response } = await Api.post(
          `/admin/quests/${quest.uid}/review`
        )
        quest.status = response.status

        this.snackbar = {
          show: true,
          message: 'Quest has been hidden for review.',
          color: 'primary'
        }
      } catch (e) {
        this.snackbar = {
          show: true,
          message: 'Something went wrong performing this action.',
          color: 'error'
        }
        quest.status = currentStatus

        throw e
      }
    },

    async approveQuest(quest) {
      const currentStatus = quest.status

      try {
        quest.status = '...'

        const { data: response } = await Api.post(
          `/admin/quests/${quest.uid}/approve`
        )
        quest.status = response.status

        this.snackbar = {
          show: true,
          message: 'Quest has been approved and is now visible.',
          color: 'primary'
        }
      } catch (e) {
        this.snackbar = {
          show: true,
          message: 'Something went wrong performing this action.',
          color: 'error'
        }
        quest.status = currentStatus

        throw e
      }
    },

    confirmDeleteQuest(quest) {
      this.deleteQuestDialog.quest = quest
      this.deleteQuestDialog.model = true
    },

    closeDeleteQuestDialog() {
      this.deleteQuestDialog.quest = null
      this.deleteQuestDialog.model = false
    },

    async deleteQuest(quest) {
      await Api.delete(`/admin/quests/${quest.uid}`)

      this.deleteQuestDialog.quest = null
      this.deleteQuestDialog.model = false

      const index = this.quests.findIndex(q => q.quest_id === quest.quest_id)
      this.quests.splice(index, 1)
    },

    updatePage(pageIndex) {
      this.meta.current_page = pageIndex
      this.quests = []
      this.getQuests()
    },
    filterQuest() {}
  }
}
</script>
<style>
td {
  border-bottom: #acadaf 1px solid;
}
tr.shaded {
  background-color: #f2f2f2;
}
tr.shaded-lighter {
  background-color: #f8f8f8;
}
</style>
